import { MinistryImgs } from "../../../ImageLibrary/imageLibrary";

interface MinistryDetails {
  title: string;
  age?: string;
  time?: string;
  location?: string;
  preverse?: {
    verse: string;
    ref: string;
  };
  mission?: string;
  vision?: string;
  content: string;
  contact?: {
    type: "email" | "phone" | "link" | undefined;
    addr: string;
    message?: string;
    messageEnd?: string;
  };
}

export interface MinistryDataItem {
  name: string;
  colour: string;
  link: string;
  image: string[];
  text?: string;
  details?: MinistryDetails[];
}

export const MinistriesData: MinistryDataItem[] = [
  {
    name: "Kids",
    colour: "#ce9400",
    link: "kids",
    image: MinistryImgs.Kids,
    details: [
      {
        title: "Kindergarten Sunday School",
        age: "3&ndash;6 years old",
        time: "Sundays, 9:45am&ndash;11:15am",
        location: "Bethesda Frankel Estate Church, 3rd floor Upper Room",
        preverse: {
          verse:
            "“Let the little children come to me, and do not hinder them, for the kingdom of God belongs to such as these.”",
          ref: "Mark 10:14",
        },
        content: `
        <p>
          The Kindergarten Sunday School (KSS) is a fun-filled family environment for preschoolers to learn about God. Through worship, Bible stories, games and craftwork, we set a foundation of Christian knowledge for our littlest ones.
        </p>
        `,
      },
      {
        title: "Junior Sunday School",
        age: "7&ndash;12 years old",
        time: "Sundays, 9:45am&ndash;11:15am",
        location: "Bethesda Frankel Estate Church, 2nd floor",
        content: `
        <p>
          The Junior Sunday School (JSS) is BFEC’s ministry for primary school children. As part of the church’s vision, we seek to groom the next generation for Christ, by planting seeds, rooting them in the word and nurturing them as they worship in spirit and truth.
        </p>
        <p>
          Through a relationship with Christ, these children learn and grow a child-like faith that will transform their lives and those around them. The harvest field might be young, but we’re growing it in expectancy of Christ’s return.
        </p>
        `,
        contact: {
          type: "email",
          addr: "kidsmin",
        },
      },
    ],
  },
  {
    name: "Youth",
    colour: "#21a37f",
    link: "youth",
    image: MinistryImgs.Youth,
    details: [
      {
        title: "Youth Service",
        time: "Sundays, 11:45am&ndash;2:00pm",
        location: "Bethesda Frankel Estate Church, Sanctuary",
        content: `
        <p>
          Welcome to Youth Service, aka the world of Instagram, Kahoot, and TikTok! We are made up of a bunch of young people, ranging from teenagers to undergraduates, and those just entering the workforce. 
        </p>
        <p>
          At Youth Service, having space to discover Jesus’ relevance in our lives and growing our love for and confidence in reading God’s Word is close to our heart. With that in mind, our programmes do not always follow the topics and format of the main adult services so that we can engage more with the contexts, questions, and challenges that youths encounter in their daily lives. Expect anything from panel discussions, workshops, to Kahoot quizzes (we know the jingles by heart) on topics such as how to read our Bibles better, what salvation means, and what missional living looks like.
        </p>
        <p>
          Discipleship and relationship-building is also very important to us at Youth Service. We are loosely organised according to age, and each group is led by one or two Youth Leaders. Discussions are usually facilitated within these small groups, so that everyone has time and safe space to build deeper relationships with one another and share more deeply about themselves.
        </p>
        <p>  
          Other than our services on Sunday, we also have bible studies and our annual youth camp held at the end of the year.
        </p>
        `,
        contact: {
          type: "phone",
          addr: "9489 4780",
          message:
            "We would love to see you at Youth Service! If you are interested in dropping by, please contact Jonathan Cho at ",
          messageEnd: ".",
        },
      },
    ],
  },
  {
    name: "Small Groups",
    colour: "#64aeb1",
    link: "small-groups",
    image: MinistryImgs.Cell,
    details: [
      {
        title: "Small Groups",
        content: `
        <p>
          How does a church grow bigger without growing colder? By growing “smaller”! Indeed, the simple, proven answer is through cell groups. When you and I are in one that honours Him, we will not only belong to a family but behave like one. 
        </p>
        <p>
          Since the first groups were formed in 1986, many of our members have experienced the warmth of mutual care and concern. Today, about 44 groups meet regularly to study His word, share prayer concerns, offer practical help to one another and even reach out to others with the Gospel. Such Christ-centered cells are indeed God’s agents of transformation. It’s how we spur one another! 
        </p>
        <p>
          Keen to join or explore further? 
        </p>
        `,
        contact: {
          type: "email",
          addr: "shirleysoo",
          message: "Email ",
          messageEnd: "!",
        },
      },
    ],
  },
  {
    name: "Evergreen 常青",
    colour: "#43897d",
    link: "evergreen",
    image: MinistryImgs.Evergreen,
    details: [
      {
        title: "Evergreen Fellowship",
        time: "Wednesdays, 10:30am",
        location:
          "Kampung Siglap Auditorium, 10 La Salle Street, Singapore 456933 (Main entrance via 55 Siglap Plain)",
        content: `
        <p>
          A few church leaders came together to seek God’s will for a Chinese Elderly Fellowship. Evergreen Fellowship was established on 8th February, 2017 (Wednesday).
        </p>
        <p>
          We aim to let more senior friends know Jesus through mid-week church activities that are beneficial to the body, mind and spirit.
        </p>
        <p>
          Activities: Karaoke, Praise and Worship, Exercise, Message, Topical Lesson, Lunch, outings, etc.
        </p>
        `,
      },
      {
        title: "常青团契",
        time: "时间：每逢星期三早上 10:30 时",
        location: "地点：福兰克福音堂大礼堂",
        content: `
        <p>
          常青团契成立于2017年2月8日（星期三），是教会领袖在祷告中寻求神的旨意而建立的。
          团契的宗旨：让更多的乐龄朋友认识、参与教会的活动，并借着这些有益身、心的活动从而认识耶稣。
          同时也为会友提供一个周间的敬拜与活动的时间，牧养他们的身、心、灵得以健康发展。
        </p>
        <p>
          活动：卡拉ok、诗歌赞美、健康操、信息分享、专题讲座、共享午餐、郊游等等。
        </p>
        `,
        contact: {
          type: undefined,
          addr: "",
        },
      },
    ],
  },
  {
    name: "40:31 Seniors",
    colour: "#027ab6",
    link: "seniors",
    image: MinistryImgs.FourOh,
    details: [
      {
        title: "40:31 Seniors",
        time: "Mondays, 10:00am&ndash;3:00pm",
        location:
          "Kampung Siglap Auditorium, 10 La Salle Street, Singapore 456933 (Main entrance via 55 Siglap Plain)",
        preverse: {
          verse: `
          Yet those who wait for the Lord <br/>
          Will gain new strength; <br/>
          They will mount up with wings like eagles, <br/>
          They will run and not get tired, <br/>
          They will walk and not become weary. <br/>
          `,
          ref: "Isaiah 40:31",
        },
        mission: `
        <p>
          To equip and enable all seniors to 
          remember the Lord and to persevere 
          in their faith giving glory to God
        </p>
        `,
        vision: `
        <p>
          Seniors Alive in Christ 
        </p>
        `,
        content: `
        <p>
          4031 aims to equip Seniors spiritually through the preaching and study of the Word on a weekly basis. We also keep seniors mentally and socially engaged through fun activities such as Rummy-O, line-dancing, excursions, and special age-related talks.
        </p>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/qZ-T1fcFxMU?si=KEZBhE1ch4DL-uP4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        `,
        contact: {
          type: "phone",
          addr: "9150 5349",
          message: "To join, contact Kim Geok at ",
          messageEnd: ".",
        },
      },
    ],
  },
  {
    name: "Missions & Evangelism",
    colour: "#7d0a7f",
    link: "missions-and-evangelism",
    image: MinistryImgs.Missions,
    details: [
      {
        title: "Missions Board",
        content: `
          <p>
            The purpose of BFEC missions is to glorify God through global and local missions through the proclamation and demonstration of the gospel of Jesus Christ. 
          </p>
          <p>
            Over the decades since BFEC's founding in 1958, missionaries such as the late Elder Seet Poon Soo, Elder Melville Szto and his wife Salome, and Dr Tan Lai Yong, 
            have inspired and paved the way for many more Frankelites to be involved in missions work locally, globally, and in a whole range of different contexts and vocations. 
          </p>
          <p>
            As we continue to build partnerships with mission agencies and like-minded organizations, our desire is to see every Frankelite develop a vision to reach the Least Reached 
            people groups of the world. 
          </p>
          <p>
            We also aim to steward our God-given resources through the support of mission workers and missional initiatives both within and beyond our church walls. 
          </p>
        `,
        contact: {
          type: "email",
          addr: "missions@bfec.org.sg",
          message:
            "To find out more how you can be involved in missions, we encourage all Frankelites to contact: ",
        },
      },
      {
        title: "Alpha",
        content: `
          <p>
            The Alpha Course is simply a pressure-free opportunity for anyone to explore the meaning of life. Run over 8 weeks, 
            it consists of thought-provoking talks & discussions about key issues, offering perspectives from the Christian faith. 
          </p>
          <p>
            Anyone who has questions about the meaning of life, or simply keen to investigate Christianity is most welcome to join the Alpha course. 
            A typical Alpha meeting involves a relaxing dinner at 7.00pm, so you can come straight from your job, class or chores. 
          </p>
          <p>
            It is followed by a 45 minute DVD talk addressing a key issue, and then a time of discussions where participants break into groups to ask questions & express opinions. 
            The whole session lasts no more than two-and-a-half hours. Many who participated in previous years’ Alpha Course were tremendously blessed. 
            Don’t miss the opportunity to explore the Christian faith in the next Alpha course.
          </p>
        `,
        contact: {
          type: "email",
          addr: "alpha@bfec.org.sg",
          message: "Contact us at ",
          messageEnd:
            " to find out more information about the next Alpha course.",
        },
      },
    ],
  },
  {
    name: "International Fellowship",
    colour: "#e1009c",
    link: "international-fellowship",
    image: MinistryImgs.IF,
    details: [
      {
        title: "International Fellowship",
        time: "Sundays, 10am&ndash;11:45am",
        location: "Bethesda Frankel Estate Church, Vineyard",
        content: `
        <p>
          This is a home away from home. At International Fellowship Ministry, domestic helpers from Indonesia, Philippines as well as other countries come together to worship and rest for the Sabbath. We have weekly Bible Studies and invite guest speakers to share on special occasions. We bond through games, crafts, outings, and most of all through serving at our church’s Cafe LEAF. 
        </p>
        <p>
          We believe in the body of Christ, thus we participate in all major church activities. Our goal is to nurture every domestic helper that comes to the Fellowship to be Christ-centered. We also hope to equip them sufficiently to lead a small group in their own country. Do take the step of faith and encourage your domestic helper to be a part of International Fellowship. 
        </p>
        `,
        contact: {
          type: undefined,
          addr: "",
        },
      },
    ],
  },
  {
    name: "Kampung Siglap",
    colour: "#e85726",
    link: "kampung-siglap",
    image: MinistryImgs.KS,
    details: [
      {
        title: "Kampung Siglap",
        content: `
        <p>
          ‘Engaging People, Connecting Hearts, Thriving Lives’ – Kampung Siglap Life Skills Training and Retreat Centre seeks to be a rejuvenating space where participants find rest, build relationships and live life fueled with a renewed sense of purpose. 
        </p>
        `,
        contact: {
          type: "link",
          addr: "/get-plugged-in/latest/kampung-siglap-how-you-can-help",
          message: "Find out how you can help ",
          messageEnd: ".",
        },
      },
    ],
  },
  {
    name: "Jesus Club",
    colour: "#e7835c",
    link: "jesus-club",
    image: MinistryImgs.JC,
    details: [
      {
        title: "Jesus Club",
        time: "Alternate Wednesdays, 7:30pm",
        location: "Bethesda Frankel Estate Church, Chapel",
        mission: `
        <ul>
          <li>
            To teach the Bible clearly and faithfully so that every member can know God to the extent that He has enabled him/her
          </li>
          <li>
            To build a community of love and concern for one another as a family in Christ
          </li>
        </ul>
        `,
        content: `
        <p>
          Jesus Club is a gathering for persons with special needs. Come join us for small group discussions, crafts and games, and supper! 
        </p>
        `,
        contact: {
          type: "phone",
          addr: "9862 9774",
          message: "Contact Hannah Ling at ",
          messageEnd: ".",
        },
      },
    ],
  },
  {
    name: "Limousine Ministry",
    colour: "#484542",
    link: "limousine",
    image: MinistryImgs.Limousine,
    details: [
      {
        title: "Limousine Ministry",
        time: "Saturdays, 8am",
        location: "Ya Kun Kaya Toast, 345 Beach Rd S(199568)",
        mission: `
        <p>
          To be Salt and Light in marketplace; through Renewal of minds and Transformation of lives and Allowing God to Work with us and through us, To His Glory
        </p>
        `,
        vision: `
        <p>
          To Journey with Love, To Love the Journey
        </p>
        `,
        content: `
        <p>
          Christ Centered Support Group Limousine is a support group that aims to make the limousine life less lonely and selfish by providing a platform for sharing work experiences, issues, advice and best practices. At the same time, we also encourage personal and family growth. 
        </p>
        <p>
          We bring chauffeurs together and build friendships over regular Saturday fellowship and impromptu coffee sessions. 
        </p>
        <p>
          We want chauffeurs to know that there is love, joy, peace, forbearance, kindness, goodness, faithfulness, gentleness and patience in the limousine industry, and most importantly, there is hope. 
        </p>
        `,
        contact: {
          type: "email",
          addr: "lukeroytan@hotmail.com",
          message: "For those who wish to join our fellowship, they can email ",
          messageEnd: ".",
        },
      },
    ],
  },
  {
    name: "Ladies’ Group",
    colour: "#c16767",
    link: "ladies",
    image: MinistryImgs.Ladies,
    details: [
      {
        title: "Ladies’ Group",
        time: "Alternate Tuesdays, 8&ndash;9pm",
        location: "Bethesda Frankel Estate Church, Chapel",
        content: `
        <p>
        The Ladies’ Group is a gathering of about 30 ladies from various churches and denominations. Some have been with us since the group began 65 years ago! 
        </p>
        <p>
        We gather to listen to speakers from BFEC and other churches, and sometimes have cooking demonstrations, health talks and exercises.
        </p>
        `,
        contact: {
          type: "phone",
          addr: "9738 6135",
          message: "To join, contact Dolly at ",
          messageEnd: ". Pick-up from Kembangan MRT station can be arranged.",
        },
      },
    ],
  },
  {
    name: "Preschool →",
    colour: "#a92d2f",
    link: "https://bethesdakindercare.sg",
    image: MinistryImgs.Kids,
    text: ``,
  },
];
