import { PeopleImgs } from "../../ImageLibrary/imageLibrary";

export interface PeopleData {
  name: string;
  image: string;
  role: string;
  content: string;
}

export const OperationsPeople: PeopleData[] = [
  {
    name: "Shirley Soo",
    image: PeopleImgs.ShirleyImage,
    role: "Operations Minister",
    content: `
        <p>
            A first generation Christian, Shirley came to know the Lord when she was 15. She counts it a real blessing to be able to serve the Lord fulltime in Cru Singapore in her earlier years and now in BFEC as operations minister overseeing all aspects of church operations.
        </p>
        <p>
            Shirley is married to Aaron and they have 2 boys, Joash & Jairus.
        </p>
        `,
  },
  {
    name: "Lim Seow Cheng",
    image: PeopleImgs.SeowChengImage,
    role: "Church Secretary",
    content: `
        <p>
            Seow Cheng came on full-time staff in 2012, after 3 years as a contract staff. She is the Pulpit Ministry Coordinator ie contacting and acting as a liaison person with Sermon speakers. She also provides secretariat support to the WATCH committee (Training and Equipping) and co-ordination of various training courses, viz baptism classes, became the Church Secretary since 2015, taking care of the legal and other requirements with ACRA. 
        </p>
        <p>
            She is amazed by how God had guided her footsteps to know Him personally through the Kindergarten ministry and school classmates. She seeks to apply Rom 12:11 “Never be lacking in zeal, but keep your spiritual fervour, serving the Lord.”
        </p>
        `,
  },
  {
    name: "Jenny",
    image: PeopleImgs.JennyImage,
    role: "Accountant",
    content: `
        <p>
            Jenny, who goes by one name, hails from Indonesia. She declines to tell us when she joined the church to hide her age. She can be found hiding in her corner of the church office quietly doing her accounts work. She can be observed printing cheques from time to time, keeping track of our bank accounts, and sending money to pay the church’s bills.
        </p>
        `,
  },
  {
    name: "Ngoo Chih Yuan",
    image: PeopleImgs.NgooCYImage,
    role: "Estate Manager",
    content: `
        <p>
            Chih Yuan (or CY) first stepped into BFEC in Jan 1983 to join the 20th Company Boys’ Brigade.
        </p>
        <p>
            He has been serving in the Worship Ministry as a musician since 1988. He found his calling in the IP ministry in Feb 2006. He started work in BFEC in Dec 2009.
        </p>
        <p>
            Background was with the military and later, HR (Development). No formal training whatsoever in Engineering nor Estate Management, yet serving by His strength and guidance.
        </p>
        `,
  },
  {
    name: "John Teo",
    image: PeopleImgs.JohnTeoImage,
    role: "ICT & Tech Manager",
    content: `
        <p>
            John has been in BFEC for as long as he can remember, having attended BKK and grown up in BFEC’s Sunday Schools and in the 20th Coy Boys’ Brigade.
        </p>
        <p>
            He served as a volunteer on the church Tech Crew for many years before eventually becoming the head of the Tech Ministry.
        </p>
        <p>
            He is an architect by training, but ended up working in IT. He is extremely passionate about technology and how it can be used to enhance ministry.
        </p>
        <p>
            In 2013, he became a full time staff of BFEC as Info-Comms Technology and Technical Manager. His role includes managing the church website, database, computer systems, network infrastructure, as well as technical aspects (sound, video, projection, etc.) of Sunday worship services and other special events.
        </p>
        <p>
            He also volunteers with the Youth Ministry and in his free time, he enjoys tinkering with computers, photography, videography, flying drones, playing the guitar, and traveling.
        </p>
        `,
  },
  {
    name: "Hwang Ching Yun",
    image: PeopleImgs.ChingYunImage,
    role: "Operations Executive",
    content: `
        <p>
            Ching Yun came to BFEC in her teens. She was called to full-time work in her 20s. She joined Youth for Christ and then New Tribes Mission (NTM) in Papua New Guinea. She worked part-time in BFEC and NTM (Singapore) till 2018.
        </p>
        <p>
            Now she works only in BFEC, as secretary to the Missions Board and helping with office administration and events, etc. Some things she enjoys: eating, travelling, watching Korean dramas.
        </p>
        `,
  },
  {
    name: "Julienne Ng",
    image: PeopleImgs.JulienneImage,
    role: "Digital & Creative Outreach Minister",
    content: `
        <p>
            Julienne has been a freelance illustrator, graphic designer and lecturer since graduation. She answered the call to serve in church as she believes in today’s need for digital outreach, and has a desire to gather other creatives to worship God through art and design. She is married to Kelvin and they have two daughters, Theo and Reia.
        </p>
        `,
  },
  {
    name: "Sharon Ngoo",
    image: PeopleImgs.SharonImage,
    role: "Worship Minister / Young Adults",
    content: `
        <p>
            Sharon is learning to know, love and worship Jesus ever more rightly, deeply and wholeheartedly. She hopes to help others in the body of Christ do the same through the medium of music and by shepherding those under her charge&ndash;members of the worship ministry and the young adults of BFEC.
        </p>
        `,
  },
  {
    name: "Sumawati Agus Kandijani",
    image: PeopleImgs.SumawatiImage,
    role: "Accounts Executive",
    content: `
        <p>
            Hailing from Indonesia, Suma had spent 14 years as a quantity surveyor in Singapore before God opened the door to the accounting/finance field. She feels really blessed that she can utilize her skills to work in a Christian organisation. She joined our team as an accounts executive from May 2024.
        </p>
        `,
  },
  {
    name: "Joy Lim",
    image: PeopleImgs.JoyLimImage,
    role: "HR Executive",
    content: `
        <p>
            Joy had the privilege of encountering the Lord at a young age after reading a Christian leaflet. She spent more than 15 years in the private sector, primarily in operations and human resources. Now she is blessed to serve alongside the BFEC team and witness firsthand the dedication of many individuals committed to living for the audience of ONE.
        </p>
        `,
  },
  {
    name: "Chong Lee Choo",
    image: PeopleImgs.ChongLeeChooImage,
    role: "HR Consultant",
    content: `
        <p>
            Lee Choo was transferred to BFEC from Life Bible Presbyterian Church in 1995.  
        </p>
        <p>
            After many years of corporate HR work, she joined the church team in July 2024 as a part-time project consultant to review, setup and execute HR policies, practices and processes in BFEC and in BKC, as it gets ready for conversion into a full-day childcare service.
        </p>
        <p>
            She is thankful for the opportunity to serve in the areas of her training, expertise and experience, and prays God will make her contributions meaningful and purposeful for His glory and the good of people. She is delighted to work alongside the team in church.
        </p>
        <p>
            Lee Choo would also like to stay active in sports and study of the Word to keep fit, healthy and strong physically, mentally and spiritually, in the Lord. She is married to Chen Nam and they have 2 children.  
        <p>
        `,
  },
  {
    name: "Dorea Cho",
    image: PeopleImgs.DoreaChoImage,
    role: "Digital and Creative Outreach Executive",
    content: `
        <p>
            “A jack of some trades serving a Master of One”. If forced to give an answer, this is what Dorea feels like most of the time!
        </p>
        <p>
            Previously a medical social worker in a public hospital for over a decade, she has learned over time that help often comes by listening to others’ stories. 
        </p>
        <p>
            This has taught her to how embrace the art of storytelling through the work she does with digital media, for the Church. She is discovering that every 'yes' to Jesus is met with grace upon grace, as God multiplies whatever she can offer Him.
        </p>
        <p>
            Dorea is a daughter, wife to Jonathan, and mother of three little ones.
        </p>
        `,
  },
];

export const PastoralPeople: PeopleData[] = [
  {
    name: "Jimmy Tan",
    image: PeopleImgs.JimmyTanImage,
    role: "Elder & Deputy Chairman",
    content: `
        <p>
            Elder Jimmy came to faith at 11 in Bedok Methodist Church but followed his brother to BFEC in 1977. He grew spiritually through the Senior Sunday School, Youth For Christ, the Adults Training Class and Varsity Christian Fellowship. Jim was baptised in 1980 and was a cell leader of several groups.
        </p>
        <p>
            After NUS, Jim joined S’pore Broadcasting Corporation (now Mediacorp) and helped set up Channel NewsAsia. 12 years later, he resigned to wait upon the Lord; and soon joined Touch Media Ministry to produce movies for local & regional church use. He subsequently became a Project Manager with Yellow Ribbon Project for 1 year. A Deacon since 1998 and Elder since 2006, he joined BFEC full time on 1 Apr 2006, as Youth & Operations Minister, helping Council to plan & coordinate all aspects of church life. He was Chairman for 6 years (2010-2015). Now, he is Deputy Chairman and overall Full-Time Elder in charge of discipleship, pastoral and operations.
        </p>
        <p>
            His passion is to communicate so as to edify, be it through ministering words, pictures or projects that enable others. Elder Jim unwinds in walks and swims, often with wife Monika whom he married in 1994. They have two daughters Sharon-Anne & Hannah-Jayne. His parents, Mr & Mrs Tan Kim Suan, are also baptised members of our church.
        </p>
        `,
  },
  {
    name: "Kuah Leck Ping",
    image: PeopleImgs.LeckPingImage,
    role: "Pastoral Staff",
    content: `
        <p>
            Baptised in BFEC in 1995, known endearingly as ”Ping-san” or “Ping-chan”, Leck Ping was a piano teacher from JC days until God called her in 2000 to do Japanese mission. Then, God equipped her as a missionary trainee for 2 years in BFEC pastoral ministry, followed by 2 ½ years OMF Japan associate work, 2 years MAIS study in SBC and finally 4 years OMF Japan mission work. God led her back to serve as a BFEC pastoral staff from 2012 until today.
        </p>
        <p>
            She likes to read books in Japanese and Chinese as she finds Kanji / Chinese (Traditional) characters beautiful. Though now she is no longer in Japan, God still brings Japanese contacts to her at different times. 
        </p>
        <p>    
            In BFEC, she has many opportunities to apply her interest in languages, particularly English&ndash;Chinese translation & interpretation. Her other interest is to talk to non-English speaking elderly people in limited Hokkien and Cantonese which never fail to make them laugh. She gives pastoral care to the elderly members in our church.
        </p>
        <p>
            She is simply grateful to serve God, not because of what she can do but for discovering that He is her Father in Heaven.
        </p>
        `,
  },
  {
    name: "Tan Fang Fang",
    image: PeopleImgs.FangFangImage,
    role: "Pastoral Staff Team Lead",
    content: `
        <p>
            Fang Fang is amazed and grateful that Jesus Christ would save a sinner like her by dying on the cross on her behalf.
        </p>
        <p>
            She feels a great privilege to be part of the pastoral team and hopes that through biblical counselling, more people will see and savour the beauty of Jesus Christ.
        </p>
        `,
  },
  {
    name: "Kelvin Ng",
    image: PeopleImgs.KelvinImage,
    role: "Pastoral Staff",
    content: `
        <p>
           Kelvin transferred to BFEC when he got married to Julienne, being excited to join the new Soccer Team and the youth ministry.
        </p>
        <p>
           He served as Youth Superintendent from 2017-2019, went on missions to the Philippines with his family from 2019-2020, and came back to join the pastoral team full time. 
        </p>
        <p>
           His work includes Social Action and Family Ministry, but he’s always ready to shoot some hoops with the youth. 
        </p>
        `,
  },
  {
    name: "Jacqueline Woon",
    image: PeopleImgs.JacquelineImage,
    role: "Children's Minister",
    content: `
        <p>
            Jac has been a “Frankelite” almost literally all her life, having attended Sunday School in BFEC since she was 3 years old.
        </p>
        <p>
            She joined her husband to serve in the JSS ministry in 2006, and her passion and heart for the children and their parents only grew with each passing year, deepening her desire to bring both children and families into a more intimate relationship with Abba Father.
        </p>
        `,
  },
  {
    name: "Jason Sin",
    image: PeopleImgs.JasonImage,
    role: "Elder",
    content: `
        <p>
            Jason grew up in BFEC in the awesome ‘80s but only in the late ‘90s did he begin to truly see the awfulness of his own sin, and the awesomeness of his Lord and Saviour. He continues to this day to be amazed by God’s immeasurable grace and mercy. 
        </p>
        <p>
            In his former life, Jason was a financial derivatives lawyer but has traded that for the opportunity to serve full-time in Christian ministry, especially in the preaching and teaching of God’s word. Jason received his theological training (M.Div.) at the Evangelical Theological College of Asia in Singapore and is grateful that he could. 
        </p>
        <p>
            Jason is married to Adelene and they have three children.
        </p>
      `,
  },
  {
    name: "黄恩施, Grace Wee",
    image: PeopleImgs.GraceWeeImage,
    role: "传道 (preacher)",
    content: `
        <p>
            我从小在基督徒家庭长大。在成长的过程中有很多生命以及信仰的挣扎。因此，让我认识了自己的软弱也更加认识这位信实、满有怜悯和恩典的神。因此我决定一生跟随及服侍祂，因为祂配得。
        </p>
    `,
  },
  {
    name: "Daniel Lim",
    image: PeopleImgs.DanielLimImage,
    role: "传道 (preacher)",
    content: `
        <p>
            这是我的呼召与使命：<br>
            在自己的生命中看见呼召的恩典，<br>
            在别人的需要上看见自己的使命。<br>
            神不需要伟大的人来见证祂的伟大，<br>
            祂只要敬爱祂的人来见证祂的作为。
        </p>
        <p>
            This is my calling and mission: <br>
            See the grace of God's calling in my life, <br>
            and see my mission in the needs of others. <br>
            God does not need great people to prove His greatness, <br>
            He only needs those who love Him to witness His deeds. <br>
        </p>
    `,
  },
];
