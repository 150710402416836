import { SQLNullTime } from "./components/Interfaces/Interfaces";
import dayjs from "dayjs";
import "dayjs/locale/en-sg";
import { format } from "date-fns";
import sanitize from "sanitize-html";
import { Environment } from "./ampli";

dayjs.locale("en-sg");

export const getEnv = (): Environment => {
  if (process.env.NODE_ENV !== "production") return "development";
  if (process.env.REACT_APP_ENV === "prod") return "production";
  return "staging";
};

export const prettyDate = (givenDate: string, dateFormat?: string) => {
  const date = new Date(givenDate);

  return format(date, dateFormat ?? "E, d LLL yyyy");
};

export const prettyDateTime = (givenDate: string) => {
  const date = new Date(givenDate);
  return date.toLocaleString("default", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
};

export const checkPostStatus = (data?: SQLNullTime | null) => {
  if (data == null || !data.Valid || !data.Time) {
    return "Draft";
  }

  if (Date.parse(data.Time) <= Date.now()) {
    return "Published";
  } else {
    return "Scheduled";
  }
};

const MAX_BLURB_LENGTH = 500;

export const buildBlurb = (content: string) => {
  const cleaned = sanitize(content, { allowedTags: [] }).split("\n");
  const blurbItems = [cleaned[0]];
  let blurbLength = cleaned[0].length;
  for (let i = 1; i < cleaned.length; i++) {
    if (cleaned[i].length === 0) continue;
    blurbLength += cleaned[i].length;
    if (blurbLength < MAX_BLURB_LENGTH) {
      blurbItems.push(cleaned[i]);
    } else {
      break;
    }
  }
  if (blurbItems[blurbItems.length - 1] !== "") {
    blurbItems[blurbItems.length - 1] += "...";
  }

  return blurbItems;
};

const SPACES_URL = "https://bfec.sgp1.digitaloceanspaces.com/";

export const formatURL = (url: string) => {
  if (url.startsWith("http")) return url;
  return SPACES_URL + url;
};

export const getPathPrefix = (pathname: string) =>
  pathname.substring(0, pathname.lastIndexOf("/", pathname.length - 2));
